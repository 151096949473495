import { IState, IAsset } from '@/types/store';

export const state: IState = {
  loading: false,
  userInfo: {
    PK: '',
    SK: '',
    address: null,
    avatar: null,
    jobTitle: '',
    company: {
      id: '',
      domain: '',
      name: '',
      logo: {
        url: ''
      }
    },
    email: null,
    firstName: null,
    id: null,
    lastName: null,
    onboarding: false,
    phoneNumber: '',
    type: ''
  },
  getBiddersToRendering: [],
  getHeadersToRendering: null,
  isNeedShowAssetBidders: false,
  bidDescription: '',
  asset: {} as IAsset,
  phaseValues: [],
  proposaInfo: {
    id: '',
    status: ''
  },
  snackbar: {
    text: '',
    subtext: '',
    isOpened: false,
    color: 'red',
    closable: false
  },
  sync: {
    targets: 0,
    timeDiff: null,
    pending: false,
    fetchInterval: 0
  }
};
