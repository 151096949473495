import { API, GraphQLResult } from '@aws-amplify/api';
import { IUser } from '@/types/API';

export const fetchUserInfo = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[0].endpoint;

  const query = `query {
        user: getUserInfo {
            PK
            SK
            id
            email
            type
            avatar
            firstName
            lastName
            phoneNumber
            onboarding
            company {
              name
              id
              domain
              logo {
                url
              }
            }
            address {
                address
                officeAddress
                city
                state
                zip
            }
        }
    }`;

  return API.graphql<GraphQLResult<IUser>>({
    query,
    variables: {}
  });
};
