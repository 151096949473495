<template>
  <v-row class="mt-10">
    <v-col cols="9">
      <bid-form :is-disabled-form="!isDisabledForm || isLoading" />
      <bid-view-actions
        class="mt-6"
        :is-disabled-form="!isDisabledForm || isLoading"
      />
    </v-col>
    <v-col cols="3">
      <bid-view-auction-info>
        <template v-if="getIsNeedShowAssetBidders" #biddersTable>
          <BidViewBidders
            :get-headers-to-rendering="getHeadersToRendering"
            :bidders="getBiddersToRendering"
            :currency="getCurrency"
          />
        </template>
      </bid-view-auction-info>
      <bid-view-corporate-client class="mt-6" />
      <bid-view-law-firm class="mt-6" />
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { IUserInfo } from '@/types/store';
import BidForm from '@/components/BidView/BidForm.vue';
import BidViewActions from '@/components/BidView/BidViewActions.vue';
import BidViewBidders from '@/components/BidView/BidViewBidders.vue';
import BidViewAuctionInfo from '@/components/BidView/BidViewAuctionInfo.vue';
import BidViewCorporateClient from '@/components/BidView/BidViewCorporateClient.vue';
import BidViewLawFirm from '@/components/BidView/BidViewLawFirm.vue';

// #constants
const { dispatch, getters } = useStore();

// #data
const isLoading = ref<boolean>(false);
const companyGrant = ref({});

// #computed
const getCurrency = computed<string>(() => {
  const { currency = '' } = getAsset.value ?? {};
  return currency;
});
const getUser = computed<IUserInfo>(() => {
  return getters['getUser'];
});
const getAsset = computed(() => {
  return getters['getAsset'];
});
const getIsNeedShowAssetBidders = computed(() => {
  return getters['getIsNeedShowAssetBidders'];
});
const getBiddersToRendering = computed(() => {
  return getters['getBiddersToRendering'];
});
const getHeadersToRendering = computed(() => {
  return getters['getHeadersToRendering'];
});
const assetId = computed<string>(() => {
  const { id = '' } = getAsset.value ?? {};
  return id;
});
const companyId = computed<string>(() => {
  const {
    company = {
      id: ''
    }
  } = getUser.value ?? {};
  const { id = '' } = company ?? {};
  return id;
});
const isDisabledForm = computed(() => {
  const { id = '' } = getUser.value ?? {};
  const {
    draftPreBid = {
      userId: ''
    },
    preBid = {
      userId: ''
    }
  } = companyGrant.value ?? {};
  const { userId: draftUserId = '' } = draftPreBid ?? {};
  const { userId: preBidUserId = '' } = preBid ?? {};
  const isPatrticipant = [draftUserId, preBidUserId].includes(id);

  if (draftUserId || preBidUserId) {
    return isPatrticipant;
  }

  return true;
});

// #methods
const getCompanyGrant = async () => {
  isLoading.value = true;
  try {
    const { data } = await dispatch('getCompanyGrant', {
      assetId: assetId.value,
      companyId: companyId.value
    });
    const { getCompanyGrant = {} } = data ?? {};
    companyGrant.value = getCompanyGrant;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await getCompanyGrant();
});
</script>
