import { API, GraphQLResult } from '@aws-amplify/api';
import {
  IProposal,
  IVariablesProposal,
  IUpdateProposal,
  IDeleteProposal,
  IBid,
  IUpdateBid
} from '@/types/API';

export const createPreBid = (variables: IVariablesProposal) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[0].endpoint;

  const query = /* GraphQL */ `
    mutation createProposal(
      $assetId: String!
      $bidDescription: String
      $partsOfBidValue: [String]!
      $partsOfBidValueCount: Int!
      $status: ProposalStatus
    ) {
      createProposal(
        assetId: $assetId
        partsOfBidValue: $partsOfBidValue
        partsOfBidValueCount: $partsOfBidValueCount
        bidDescription: $bidDescription
        status: $status
      ) {
        id
        ownerId
        owner {
          PK
          SK
          id
          email
          type
          avatar
          firstName
          lastName
          phoneNumber
        }
        assetId
        bidValue
        partsOfBidValue
        status
        createdAt
        updatedAt
        bidDescription
      }
    }
  `;

  return API.graphql<GraphQLResult<IProposal>>({
    query,
    variables
  });
};
export const updateProposal = (variables: IUpdateProposal) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[0].endpoint;
  const query = /* GraphQL */ `
    mutation updateProposal(
      $assetId: String!
      $partsOfBidValue: [String]
      $status: ProposalStatus
      $bidDescription: String
    ) {
      updateProposal(
        assetId: $assetId
        partsOfBidValue: $partsOfBidValue
        status: $status
        bidDescription: $bidDescription
      ) {
        id
        ownerId
        owner {
          PK
          SK
          id
          email
          type
          avatar
          firstName
          lastName
          phoneNumber
        }
        assetId
        bidValue
        partsOfBidValue
        status
        createdAt
        updatedAt
        bidDescription
      }
    }
  `;

  return API.graphql<GraphQLResult<IProposal>>({
    query,
    variables
  });
};
export const deleteProposal = (variables: IDeleteProposal) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[0].endpoint;
  const query = /* GraphQL */ `
    mutation deleteProposal($assetId: String!) {
      deleteProposal(assetId: $assetId) {
        id
        ownerId
        owner {
          PK
          SK
          id
          email
          type
          avatar
          firstName
          lastName
          phoneNumber
        }
        assetId
        bidValue
        partsOfBidValue
        status
        createdAt
        updatedAt
      }
    }
  `;

  return API.graphql<GraphQLResult<IProposal>>({
    query,
    variables
  });
};
export const updateBid = (variables: IUpdateBid) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[1].endpoint;
  const query = /* GraphQL */ `
    mutation updateBid(
      $auctionId: String!
      $save: Boolean!
      $createOrUpdate: Boolean
      $attributes: BidAttributeUpdate
    ) {
      updateBid(
        auctionId: $auctionId
        save: $save
        createOrUpdate: $createOrUpdate
        attributes: $attributes
      ) {
        PK
        SK
        id
        auctionId
        ownerId
        owner {
          PK
          SK
          id
          email
          type
          avatar
          firstName
          lastName
          phoneNumber
        }
        assetId
        bidValue
        partsOfBidValue
        createdAt
        docType
      }
    }
  `;

  return API.graphql<GraphQLResult<IBid>>({
    query,
    variables
  });
};
