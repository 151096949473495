<template>
  <div class="bid-actions d-flex justify-space-between">
    <v-btn
      variant="text"
      color="#18A1C8"
      :disabled="isLoading"
      @click="closeModal"
      >Cancel</v-btn
    >
    <div>
      <bid-view-save-as-draft
        v-if="isProposal && isDraftButtonNeed"
        :disabled="isLoading || isDisabledForm"
      />
      <v-btn
        v-if="proposalId"
        variant="flat"
        :disabled="isLoading || isDisabledForm"
        :loading="isWithdrawLoading"
        color="#FF5252"
        class="bid-actions__withdraw ml-6"
        @click="onDeleteProposal"
        >Withdraw</v-btn
      >
      <v-btn
        variant="flat"
        color="#18A1C8"
        :disabled="isDisabledForm"
        :loading="isLoading"
        class="bid-actions__submit ml-6"
        @click="onSubmit"
        >{{ getButtonValue }}</v-btn
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import useBid from '@/composables/useBid';
import BidViewSaveAsDraft from './BidViewSaveAsDraft.vue';
import { withDefaults, defineProps, computed } from 'vue';

// #interfaces
interface IProps {
  isDisabledForm: boolean;
}

// #props
withDefaults(defineProps<IProps>(), {
  isDisabledForm: false
});

// #constants
const {
  onUpdateProposal,
  onDeleteProposal,
  onCreateProposal,
  onUpdateBid,
  closeModal,
  isLoading,
  isWithdrawLoading,
  proposalId,
  isProposal,
  proposalStatus
} = useBid();

// #computed
const getButtonValue = computed(() => {
  if (proposalStatus.value === 'draft') {
    return 'Submit';
  }
  return proposalId.value ? 'Update' : 'Submit';
});
const isDraftButtonNeed = computed<boolean>(() => {
  return proposalStatus.value === 'draft' || !proposalStatus.value;
});

// #methods
const onSubmit = () => {
  if (isProposal.value) {
    return proposalId.value
      ? onUpdateProposal('initial')
      : onCreateProposal('initial');
  }
  onUpdateBid();
};
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors.scss';

.bid-actions {
  &__submit,
  &__withdraw {
    color: $color-ui-white !important;
  }
}
</style>
