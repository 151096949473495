<template>
  <money3
    v-model.number="amount"
    v-bind="config"
    :disabled="disabled"
    class="app-bid-input"
  />
</template>

<script setup lang="ts">
import {
  defineProps,
  withDefaults,
  defineEmits,
  ref,
  onMounted,
  watch
} from 'vue';

// #variables
const amount = ref(0);

// #data
const config = {
  masked: true,
  prefix: '',
  suffix: '',
  thousands: ',',
  decimal: '.',
  precision: 0,
  disableNegative: true,
  disabled: false,
  min: 0,
  max: null,
  allowBlank: false,
  minimumNumberOfCharacters: 0,
  shouldRound: true,
  focusOnRight: false
};

// #emits
const emit = defineEmits(['input:modelValue']);

// #types
interface IProps {
  modelValue: number;
  min?: number;
  disabled: boolean;
  disableErrors?: boolean;
  prefix: string;
}

// #props
const props = withDefaults(defineProps<IProps>(), {
  modelValue: 0,
  min: 0,
  prefix: '',
  disabled: false
});

onMounted(() => {
  amount.value = props.modelValue;
});

watch(amount, () => {
  emit('input:modelValue', amount.value);
});
</script>

<style scoped lang="scss">
$height: 42px;

.app-bid-input {
  height: $height;
  max-height: $height;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
  padding: 16px;
  text-align: right;
  &:focus {
    outline: none;
  }
}
</style>
