import { API, GraphQLResult } from '@aws-amplify/api';
import {
  IProposal,
  IListGrantsParams,
  IGetCompanyGrantParams,
  ICompanyGrant
} from '@/types/API';

export const getListGrants = (variables: IListGrantsParams) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[0].endpoint;

  const query = /* GraphQL */ `
    query listGrants(
      $assetId: String
      $companyId: String
      $userId: String
      $limit: Int
      $nextToken: String
    ) {
      listGrants(
        assetId: $assetId
        companyId: $companyId
        userId: $userId
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          status
          access
          assetId
          user {
            email
            firstName
            lastName
          }
          userId
          companyId
          company {
            name
            domain
            id
            logo {
              thumbnail
              type
              url
              name
            }
          }
          companyGrant {
            assetId
            companyId
            company {
              name
              domain
              id
              logo {
                thumbnail
                type
                url
                name
              }
            }
            conflictCheck {
              userId
              user {
                email
                firstName
                lastName
              }
              updatedAt
            }
            preBid {
              userId
              user {
                email
                firstName
                lastName
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        count
        nextToken
      }
    }
  `;

  return API.graphql<GraphQLResult<IProposal>>({
    query,
    variables
  });
};

export const getCompanyGrant = (variables: IGetCompanyGrantParams) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[0].endpoint;

  const query = `
          query getCompanyGrant($assetId: String!, $companyId: String) {
            getCompanyGrant(assetId: $assetId, companyId: $companyId) {
              assetId
              companyId
              createdAt
              updatedAt
              company {
                name
                domain
                id
              }
              conflictCheck {
                userId
                updatedAt
              }
              draftPreBid {
                userId
                updatedAt
              }
              preBid  {
                userId
                updatedAt
              }
            }
          }
      `;

  return API.graphql<GraphQLResult<ICompanyGrant>>({
    query,
    variables
  });
};
