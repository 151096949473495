import { IState } from '@/types/store';
import { IUserInfo } from '@/types/store';
export const getters = {
  getAssetTitle: (state: IState) => {
    const { asset = { title: '' } } = state;
    const { title = '' } = asset ?? {};

    return title;
  },
  getUser: (state: IState): IUserInfo => {
    return state.userInfo;
  },
  getBusinessInfo: (state: IState) => {
    const {
      userInfo = {
        company: {
          logo: {
            url: ''
          },
          name: null
        },
        companyName: '',
        email: [],
        firstName: '',
        jobTitle: '',
        lastName: '',
        license: null,
        phoneNumber: []
      }
    } = state ?? {};
    const {
      company = {
        name: '',
        logo: {
          url: ''
        }
      },
      email = '',
      firstName = '',
      jobTitle = '',
      lastName = '',
      phoneNumber = ''
    } = userInfo ?? {};
    const {
      name: companyName = '',
      logo = {
        url: ''
      }
    } = company ?? {};
    const { url: companyLogo = '' } = logo ?? {};

    return {
      companyLogo,
      companyName,
      email,
      firstName,
      jobTitle,
      lastName,
      phoneNumber
    };
  },
  getUserEmail: (state: IState) => {
    const {
      userInfo = {
        email: ''
      }
    } = state ?? {};
    const { email = '' } = userInfo ?? {};

    return email;
  },
  getSnackBar: (state: IState) => {
    const {
      snackbar = {
        text: '',
        subtext: '',
        isOpened: false,
        color: 'red',
        closable: false
      }
    } = state ?? {};
    const {
      text = '',
      subtext = '',
      isOpened = false,
      color = 'red',
      closable = false
    } = snackbar ?? {};

    return {
      text,
      subtext,
      isOpened,
      color,
      closable
    };
  },
  getAsset: (state: IState) => {
    return state.asset ?? {};
  },
  getPhaseValues: (state: IState) => {
    return state.phaseValues;
  },
  getProposalInfo: (state: IState) => {
    return state.proposaInfo;
  },
  getBidDescription: (state: IState) => {
    return state.bidDescription;
  },
  getBiddersToRendering: (state: IState) => {
    return state.getBiddersToRendering;
  },
  getHeadersToRendering: (state: IState) => {
    return state.getHeadersToRendering;
  },
  getIsNeedShowAssetBidders: (state: IState) => {
    return state.isNeedShowAssetBidders;
  }
};
