import { createStore, createLogger } from 'vuex';

import { state } from '@/store/state';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  state,
  mutations,
  getters,
  actions,
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
