import axios from 'axios';
import { fetchUserInfo } from '@/api/user';
import {
  createPreBid,
  deleteProposal,
  updateBid,
  updateProposal
} from '@/api/bids';
import { getListGrants, getCompanyGrant } from '@/api/grants';
import { Commit, Dispatch } from 'vuex';
import { IState } from '@/types/store';
import {
  IVariablesProposal,
  IUpdateProposal,
  IDeleteProposal,
  IUpdateBid,
  IListGrantsParams,
  IGetCompanyGrantParams
} from '@/types/API';
import { IPhase } from '@/types/phases';

export const actions = {
  close() {
    parent.postMessage(
      {
        action: 'close'
      },
      '*'
    );
  },
  onProposalBidWereCreated() {
    parent.postMessage(
      {
        action: 'proposal-created'
      },
      '*'
    );
  },
  async fetchUserInfo({ commit }: { commit: Commit }) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const { data } = await fetchUserInfo();
      const { user } = data ?? {};
      commit('setUserInfo', user);
    } catch (error) {
      commit('errorNotification', {
        error
      });
    }
  },
  async createProposal(
    { commit }: { commit: Commit },
    payload: IVariablesProposal
  ) {
    try {
      await createPreBid(payload);
    } catch (error) {
      commit('errorNotification', {
        error
      });
      return Promise.reject();
    }
  },
  async updateProposal(
    { commit }: { commit: Commit },
    payload: IUpdateProposal
  ) {
    try {
      await updateProposal(payload);
    } catch (error) {
      commit('errorNotification', {
        error
      });
      return Promise.reject();
    }
  },
  async deleteProposal(
    { commit }: { commit: Commit },
    payload: IDeleteProposal
  ) {
    try {
      await deleteProposal(payload);
    } catch (error) {
      commit('errorNotification', {
        error
      });
      return Promise.reject();
    }
  },
  async updateBid({ commit }: { commit: Commit }, payload: IUpdateBid) {
    try {
      await updateBid(payload);
    } catch (error) {
      commit('errorNotification', {
        error
      });
      return Promise.reject();
    }
  },
  async getListGrants(
    { commit }: { commit: Commit },
    payload: IListGrantsParams
  ) {
    try {
      return await getListGrants(payload);
    } catch (error) {
      commit('errorNotification', {
        error
      });
      return Promise.reject();
    }
  },
  async getCompanyGrant(
    { commit }: { commit: Commit },
    payload: IGetCompanyGrantParams
  ) {
    try {
      return await getCompanyGrant(payload);
    } catch (error) {
      commit('errorNotification', {
        error
      });
      return Promise.reject();
    }
  },
  setPhasesValues({ commit }: { commit: Commit }, payload: IPhase[]) {
    commit('setPhasesValues', payload);
  },
  initSync({
    state,
    commit,
    dispatch
  }: {
    state: IState;
    commit: Commit;
    dispatch: Dispatch;
  }) {
    const interval = setInterval(() => {
      dispatch('fetchServerTime');
    }, 60000);
    commit('setSyncData', {
      interval,
      active: true
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    commit('setTargets', state.targets + 1);
    dispatch('fetchServerTime');
  },
  stopSync({ state, commit }: { state: IState; commit: Commit }) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    commit('setTargets', state.targets - 1);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (state.targets < 1) {
      commit('setTargets', 0);
      commit('setSyncData', {
        interval: null
      });
    }
  },
  fetchServerTime({ commit }: { commit: Commit }) {
    commit('setPending', true);
    axios('https://date.dev.counselops.com/now', {
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
      .then((res) => {
        const serverTime = res.headers.date;
        const diffTime = Math.round(
          (new Date(serverTime).getTime() - new Date(Date.now()).getTime()) /
            1000
        );
        commit('setTimeDiff', diffTime);
      })
      .finally(() => {
        commit('setPending', false);
      });
  }
};
