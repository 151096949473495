<template>
  <div>
    <div class="asset-bidders">
      <div class="asset-bidders__header">
        <div>{{ getHeadersToRenderingName }}</div>
        <div>{{ getHeadersToRenderingValue }}</div>
      </div>
      <div class="asset-bidders__content">
        <div
          v-for="(bidder, i) in bidders"
          :key="i"
          class="asset-bidders__item"
          :class="
            getHeadersToRenderingName !== 'Rank' &&
            'asset-bidders__item--without-rank'
          "
        >
          <div
            v-if="getHeadersToRenderingName === 'Rank'"
            class="asset-bidders__item--first"
          >
            {{ bidder.rank }}
          </div>
          <div class="asset-bidders__item--second">{{ bidder.name }}</div>
          <div class="asset-bidders__item--third">
            {{ formatCurrency(bidder.price) }}
          </div>
        </div>
        <div v-if="!bidders.length" class="asset-bidders__empty">
          There are no bidders yet
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, withDefaults, computed } from 'vue';

// #interfaces
interface IBidder {
  rank: string | number;
  name: string;
  price: string | number;
}
interface IProps {
  currency: string;
  bidders: IBidder[];
  getHeadersToRendering: { name: string; value: string };
}

// #props
const props = withDefaults(defineProps<IProps>(), {
  currency: '',
  bidders: () => []
});

// #methods
const formatCurrency = (value: number | string): string => {
  if (!value) return '';
  const currency = props.currency;

  // Check if `value` is a string, if so, parse it into a number
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0
  }).format(numericValue);
};

// #computed
const getHeadersToRenderingValue = computed(() => {
  const { value } = props.getHeadersToRendering ?? {};
  return value;
});
const getHeadersToRenderingName = computed(() => {
  const { name } = props.getHeadersToRendering ?? {};
  return name;
});
</script>
<style scoped lang="scss">
@import '@/assets/style/colors';
.asset-bidders {
  border: 1px solid $color-ui-black;
  border-radius: 8px;
  margin-top: 24px;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
  }
  &__content {
    max-height: 355px;
    min-height: 140px;
    overflow: auto;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #616161;
    max-width: 282px;
    &--first {
      width: 15%;
      max-width: 33px;
      text-align: start;
    }
    &--second {
      width: 35%;
      max-width: 77px;
      text-align: start;
    }
    &--third {
      width: 50%;
      max-width: 110px;
      text-align: end;
    }
    &--without-rank {
      .asset-bidders__item--second {
        width: 50%;
        max-width: 110px;
      }
    }
    & div {
      margin: 0 8px;
      box-sizing: border-box;
    }
  }
  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #616161;
  }
}
</style>
