import { Storage } from '@aws-amplify/storage';
import type { StorageGetConfig, StorageGetOutput } from '@aws-amplify/storage';

const getStorageFile = (
  key: string,
  additionalProperties: StorageGetConfig<Record<string, any>> = {
    level: 'protected',
    download: true
  }
): StorageGetOutput<any> => {
  try {
    return Storage.get(key, additionalProperties);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export { getStorageFile };
