import formatDate from '@/utils/formatDate';
import type { Ref } from 'vue';
import { onUnmounted, ref } from 'vue';
const intervalStorage: Array<ReturnType<typeof setInterval> | null> = [];

interface ICountDown {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}
interface ITargetDate {
  value: Date | string | number;
  formattedValue: string;
  countdown: ICountDown;
  countdownInterval?: null | NodeJS.Timer | number | string | undefined;
}
interface ICalculateCountdown {
  days: number | string;
  hours: string;
  minutes: string;
  seconds: string;
}
interface ICalculateCountdownParams {
  timeDiff: number;
  countdownInterval: string | number;
  value: string | number;
  onStop: () => void;
}
function calculateCountdown({
  timeDiff,
  countdownInterval,
  value,
  onStop
}: ICalculateCountdownParams): ICalculateCountdown {
  const syncDiff = timeDiff || 0;
  const serverTime = Math.round(
    Math.abs(new Date(Date.now()).getTime() / 1000 + syncDiff)
  );
  const endTime = Math.floor(new Date(value).getTime() / 1000);
  if (endTime - serverTime < 0) {
    countdownInterval && onStop();
    return {
      days: '0',
      hours: '00',
      minutes: '00',
      seconds: '00'
    };
  }
  const diff = new Date((endTime - serverTime) * 1000);
  const days = Math.floor(diff.getTime() / 1000 / 60 / 60 / 24);
  const hours = diff.toISOString().substr(11, 2);
  const minutes = diff.toISOString().substr(14, 2);
  const seconds = diff.toISOString().substr(17, 2);
  return {
    days,
    hours,
    minutes,
    seconds
  };
}

export default function useCountdown() {
  onUnmounted(() => {
    intervalStorage.forEach((interval) =>
      clearInterval(<ReturnType<typeof setInterval>>interval)
    );
  });
  const targetDate: Ref<ITargetDate> = ref({
    value: '',
    formattedValue: '',
    countdown: {
      days: '0',
      hours: '00',
      minutes: '00',
      seconds: '00'
    },
    countdownInterval: null
  });
  const startCountdownInterval = (timeDiff: number): void => {
    if (!targetDate.value.countdownInterval) {
      const { countdownInterval, value } = targetDate.value;
      targetDate.value.countdownInterval = setInterval(() => {
        targetDate.value.countdown = <ICountDown>calculateCountdown(<
          ICalculateCountdownParams
        >{
          timeDiff,
          countdownInterval,
          value,
          onStop: onStopCountDown
        });
      }, 1000);
      // eslint-disable-next-line
      // @ts-ignore
      intervalStorage.push(targetDate.value.countdownInterval);
    }
  };
  const onStopCountDown = () => {
    targetDate.value.countdown = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00'
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    clearInterval(targetDate?.value.countdownInterval);
    targetDate.value.countdownInterval = null;
  };
  const setupCountdown = (timeDiff: number, date: Date | string): void => {
    targetDate.value.value = date;
    targetDate.value.formattedValue = formatDate(targetDate.value.value);
    const { countdownInterval, value } = targetDate.value;
    targetDate.value.countdown = <ICountDown>calculateCountdown(<
      ICalculateCountdownParams
    >{
      timeDiff,
      countdownInterval,
      value,
      onStop: onStopCountDown
    });
    const countdownEnded = Object.values(targetDate.value.countdown).every(
      (v) => !+v
    );
    if (countdownEnded) {
      return;
    }
    startCountdownInterval(timeDiff);
  };
  return {
    targetDate,
    setupCountdown
  };
}
