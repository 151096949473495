<template>
  <app-card-info card-title="Customer Information" class="client-info">
    <div class="d-flex">
      <v-img
        v-if="file"
        :src="file"
        alt="logo"
        loading="lazy"
        class="client-info__logo"
      />
      <div class="client-info__fields ml-6">
        <span class="client-info__fields-name">{{ getUserName }}</span>
        <br />
        <span class="d-block mt-2">{{ getCompanyName }}</span>
        <span>{{ getPhone }}</span>
      </div>
    </div>
  </app-card-info>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import AppCardInfo from '@/components/App/AppCardInfo.vue';
import { getStorageFile } from '@/utils/S3StorageUtils';
import { Utils } from '@/utils/index';
import { IBusinessInfo } from '@/types/store';

// #contants
const { getters } = useStore();

// #data
const file = ref<string | null | undefined>(null);

// #computed
const businessInfo = computed<IBusinessInfo>(() => {
  return getters['getBusinessInfo'];
});
const getLogo = computed<string>(() => {
  const {
    owner = {
      company: {
        logo: {
          url: ''
        }
      }
    }
  } = getAsset.value ?? {};

  const {
    company = {
      logo: {
        url: ''
      }
    }
  } = owner ?? {};
  const {
    logo = {
      url: ''
    }
  } = company ?? {};
  const { url = '' } = logo ?? {};

  return url;
});
const getAsset = computed(() => {
  return getters['getAsset'];
});
const getCompanyName = computed<string>(() => {
  const {
    owner = {
      company: {
        name: ''
      }
    }
  } = getAsset.value ?? {};
  const {
    company = {
      name: ''
    }
  } = owner ?? {};

  const { name = '' } = company ?? {};
  return name;
});
const getUserName = computed<string>(() => {
  const {
    owner = {
      firstName: '',
      lastName: ''
    }
  } = getAsset.value ?? {};
  const { firstName = '', lastName = '' } = owner ?? {};

  return `${firstName} ${lastName}`;
});
const getPhone = computed<string>(() => {
  const {
    owner = {
      phoneNumber: ''
    }
  } = getAsset.value ?? {};

  const { phoneNumber = '' } = owner ?? {};

  return phoneNumber.length ? Utils.formatPhoneNumber(phoneNumber) : '';
});

// #methods
const onSetRemoteFile = async (
  key: string
): Promise<string | null | undefined> => {
  if (!key) {
    return null;
  }
  try {
    return await getStorageFile(key, {
      level: 'public',
      download: false
    });
  } catch (error) {
    console.error();
  }
};

// #hooks
onMounted(async () => {
  file.value = await onSetRemoteFile(getLogo.value);
});
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors.scss';

.client-info {
  &__logo {
    width: 114px;
    height: 79px;
  }
  &__fields {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $color-ui-gray-secondary-darken;
    &-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      color: $color-ui-black;
    }
  }
}
</style>
