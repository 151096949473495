export const awsConfig = {
  Auth: {
    identityPoolId: "us-east-2:06a7d5e2-9b79-4c35-8d3e-89c05ab156cb",
    region: "us-east-2",
    userPoolId: "us-east-2_7Ep4d87MQ",
    userPoolWebClientId: "2tbk433ig29joq3mgmmh9v5mfj",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    aws_appsync_graphqlEndpoint:
      "https://blmus44pvbdtvfqcaq4zb2ujzy.appsync-api.us-east-2.amazonaws.com/graphql",
    aws_appsync_region: "us-east-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: 'asset',
        endpoint:
          'https://vppxy55qnjfx7edgq675jng4lq.appsync-api.us-east-2.amazonaws.com/graphql'
      },
      {
        name: 'auctions',
        endpoint:
          'https://x2ecmsdi4japtdbklpol7n47li.appsync-api.us-east-2.amazonaws.com/graphql'
      },
      {
        name: 'asset v2',
        endpoint:
          'https://vppxy55qnjfx7edgq675jng4lq.appsync-api.us-east-2.amazonaws.com/graphql'
      },
      {
        name: 'auctions v2',
        endpoint:
          'https://x2ecmsdi4japtdbklpol7n47li.appsync-api.us-east-2.amazonaws.com/graphql'
      }
    ],
  },
  Storage: {
    AWSS3: {
      bucket: "dev-legalau-assets-static",
      region: "us-east-2",
    },
  },
};
