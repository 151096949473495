import { generateFileKey } from '@/utils/generateFileKey';

export const Utils = {
  /*
   * Example key
   * protected/user-identity-id/image.jpg
   */
  parseFileKey(key: string) {
    return {
      key: key.split('/')[2],
      identityId: key.split('/')[1],
      level: key.split('/')[0]
    };
  },

  /*
   * Example
   * fileType = image/png
   */
  generateFileKey,

  formatPrice(price: number): string | number {
    if (!price) {
      return price;
    } else {
      return `${new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2
      }).format(price)}`;
    }
  },

  getFileIcon(type: string) {
    if (type.includes('pdf')) {
      return 'mdi-file-pdf';
    } else if (
      ['image', 'jpeg', 'jpg', 'png', 'webp'].some(
        (substr) => type.indexOf(substr) >= 0
      )
    ) {
      return 'mdi-file-image-outline';
    } else {
      return 'mdi-file';
    }
  },

  formatPhoneNumber(phoneNumber: string) {
    // Remove all non-digit characters from the input
    const cleaned = phoneNumber.replace(/\D/g, '');
    // Format the cleaned phone number with dashes: XXX-XXX-XXXX or XXX-XXXX
    return cleaned.replace(/(\d{3})(\d{1,4})/, (match, group1, group2) => {
      if (group2.length === 4) {
        return `+${group1}-${group2.slice(0, 3)}-${group2.slice(3)}`;
      } else {
        return `+${group1}-${group2}`;
      }
    });
  },
  getFormattedComment(val: string | number): string {
    return val ? val.toString().replace(/\r?\n/g, '<br>') : '';
  }
};
