import { IState, IUserInfo, IAsset, IBid, ISnackBar } from '@/types/store';
import { IPhase } from '@/types/phases';
import * as Sentry from '@sentry/vue';

export const mutations = {
  setUserInfo(state: IState, payload: IUserInfo) {
    state.userInfo = {
      ...payload
    };
  },
  setPhasesValues(state: IState, payload: IPhase[]) {
    state.phaseValues = payload.map(({ value }) => value);
  },
  setBidDescription(state: IState, payload: string) {
    state.bidDescription = payload;
  },
  setAssetInfo(
    state: IState,
    payload: {
      asset: IAsset;
      bidders: IBid[];
      phaseValues: number[] | string[];
      proposaInfo: {
        id: string;
        status: string;
      };
      bidDescription: string;
      getBiddersToRendering: { name: string; price: number | string }[];
      getHeadersToRendering: { name: string; title: string };
      isNeedShowAssetBidders: boolean;
    }
  ) {
    state.asset = {
      ...payload.asset
    };
    state.getBiddersToRendering = payload.getBiddersToRendering;
    state.getHeadersToRendering = payload.getHeadersToRendering;
    state.isNeedShowAssetBidders = payload.isNeedShowAssetBidders;
    if (payload.phaseValues) {
      state.phaseValues = [...payload.phaseValues];
    }
    if (payload.proposaInfo) {
      state.proposaInfo = payload.proposaInfo;
    }
    if (payload.bidDescription) {
      state.bidDescription = payload.bidDescription;
    }
  },
  errorNotification(
    state: IState,
    { error, text, subtext, closable }: ISnackBar
  ) {
    error && console.error(error);
    const message = text
      ? text
      : (error && error.errors && error.errors[0].message) ||
        'Something went wrong';

    Sentry.captureException(message);
    state.snackbar = {
      text: message,
      subtext: subtext || '',
      closable,
      color: 'error',
      isOpened: true
    };
  },
  showOverlay(state: IState) {
    state.loading = true;
  },
  hideOverlay(state: IState) {
    state.loading = false;
  },
  closeSnackbar(state: IState) {
    state.snackbar = {
      text: '',
      subtext: '',
      isOpened: false,
      color: 'success',
      closable: false
    };
  },
  successNotification(state: IState, { text, subtext, closable }: ISnackBar) {
    state.snackbar = {
      text,
      subtext: subtext || '',
      closable,
      color: 'success',
      isOpened: true
    };
  },
  setPending(state: IState, pending: boolean) {
    state.sync.pending = pending;
  },
  setTimeDiff(state: IState, timeDiff: number) {
    state.sync.timeDiff = timeDiff;
    state.sync.pending = false;
  },
  setSyncData(
    state: IState,
    { interval, active }: { interval: number; active: boolean }
  ) {
    if (!active) {
      clearInterval(state.sync.fetchInterval);
    }
    state.sync.fetchInterval = interval;
    state.sync.active = active;
  },
  setTargets(state: IState, targets: number) {
    state.sync.targets = targets;
  }
};
