import { useStore } from 'vuex';
import { IAsset } from '@/types/store';
import { RAW_ASSETS_STATUSES } from '@/constants/statuses';
import { ref, computed } from 'vue';

export default function useBid() {
  // #constants
  const { dispatch, commit, getters } = useStore();

  // #data
  const isLoading = ref<boolean>(false);
  const isWithdrawLoading = ref<boolean>(false);
  const isDraftLoading = ref<boolean>(false);

  // #computed
  const getAsset = computed<IAsset>(() => {
    return getters['getAsset'];
  });
  const getAssetStatus = computed<string>(() => {
    const { status = '' } = getAsset.value ?? {};
    return status;
  });
  const isProposal = computed<boolean>(() => {
    return [
      RAW_ASSETS_STATUSES.ACTIVE,
      RAW_ASSETS_STATUSES.PRE_AUCTION
    ].includes(getAssetStatus.value);
  });
  const getPhaseValues = computed<(string | number)[]>(() => {
    return getters['getPhaseValues'];
  });
  const getBidDescription = computed<string>(() => {
    return getters['getBidDescription'];
  });
  const assetId = computed<string>(() => {
    const { id } = getAsset.value ?? {};
    return id;
  });
  const auctionId = computed<string>(() => {
    const { auction = { id: '' } } = getAsset.value ?? {};
    const { id = '' } = auction ?? {};
    return id;
  });
  const proposalId = computed<string>(() => {
    const { id } = getters['getProposalInfo'] ?? {};
    return id;
  });
  const proposalStatus = computed<string>(() => {
    const { status } = getters['getProposalInfo'] ?? {};
    return status;
  });

  // #methods
  const closeModal = () => {
    setTimeout(() => {
      dispatch('close');
    }, 1000);
  };
  const onUpdateProposal = async (status = 'initial') => {
    const params = {
      id: proposalId.value,
      status: status || proposalStatus.value,
      assetId: assetId.value,
      partsOfBidValue: getPhaseValues.value,
      bidDescription: getBidDescription.value
    };

    if (params.status === 'draft') {
      isDraftLoading.value = true;
    } else {
      isLoading.value = true;
    }

    try {
      await dispatch('updateProposal', params);

      commit('successNotification', { text: 'Proposal was updated.' });
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      if (params.status === 'draft') {
        isDraftLoading.value = false;
      } else {
        isLoading.value = false;
      }
    }
  };
  const onDeleteProposal = async () => {
    const params = {
      id: proposalId.value,
      assetId: assetId.value
    };

    isWithdrawLoading.value = true;
    try {
      await dispatch('deleteProposal', params);

      commit('successNotification', { text: 'Proposal was deleted.' });
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      isWithdrawLoading.value = false;
    }
  };
  const onCreateProposal = async (status = 'initial') => {
    const params = {
      assetId: assetId.value,
      partsOfBidValue: getPhaseValues.value,
      partsOfBidValueCount: getPhaseValues.value.length,
      bidDescription: getBidDescription.value,
      status: ''
    };

    if (status) {
      params.status = status;
    } else {
      params.status = 'initial';
    }

    if (params.status === 'draft') {
      isDraftLoading.value = true;
    } else {
      isLoading.value = true;
    }
    try {
      await dispatch('createProposal', params);

      commit('successNotification', { text: 'Proposal was created.' });
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      if (params.status === 'draft') {
        isDraftLoading.value = false;
      } else {
        isLoading.value = false;
      }
    }
  };
  const onUpdateBid = async () => {
    const params = {
      auctionId: auctionId.value,
      save: true,
      createOrUpdate: true,
      attributes: {
        auctionId: auctionId.value,
        assetId: assetId.value,
        partsOfBidValue: getPhaseValues.value,
        bidDescription: getBidDescription.value
      }
    };

    isLoading.value = true;
    try {
      await dispatch('updateBid', params);

      commit('successNotification', { text: 'Bid was updated.' });
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    onUpdateProposal,
    onDeleteProposal,
    onCreateProposal,
    onUpdateBid,
    closeModal,
    isLoading,
    isWithdrawLoading,
    isDraftLoading,
    proposalStatus,
    proposalId,
    isProposal
  };
}
