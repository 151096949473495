<template>
  <v-table fixed-header class="bid-phases">
    <thead>
      <tr>
        <th class="text-left bid-phases__heading">Phase</th>
        <th class="text-left bid-phases__heading">Price</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in deserts" :key="item.title" class="bid-phases__tr">
        <td class="bid-phases__row">
          <span class="bid-phases__phase-title">{{ item.title }}</span>
          <br />
          <span
            class="bid-phases__phase-description"
            v-html="item.description"
          />
        </td>
        <td class="bid-phases__row">
          <app-bid-input
            :disabled="isDisabledForm"
            class="bid-phases__phase-input"
            :prefix="getCurrency"
            :model-value="item.value"
            @input:model-value="item.value = $event"
          />
        </td>
      </tr>
      <tr>
        <td class="bid-phases__row bid-phases__total">Total Bid</td>
        <td class="bid-phases__row bid-phases__total text-right">
          {{ getCurrency }} {{ totalSum }}
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script setup lang="ts">
import { withDefaults, defineProps } from 'vue';
import { reactive, computed, onMounted, watch } from 'vue';
import { Utils } from '@/utils';
import { IAsset } from '@/types/store';
import { IPhase } from '@/types/phases';
import { useStore } from 'vuex';
import AppBidInput from '@/components/App/AppBidInput.vue';

// #interfaces
interface IProps {
  isDisabledForm: boolean;
}

// #props
withDefaults(defineProps<IProps>(), {
  isDisabledForm: false
});

// #constants
const { getters, dispatch } = useStore();

// #data
const deserts = reactive<IPhase[]>([]);

// #computed
const getAsset = computed<IAsset>(() => {
  return getters['getAsset'];
});
const getPhaseValues = computed<(string | number)[]>(() => {
  return getters['getPhaseValues'];
});
const totalSum = computed<string | number>(() => {
  const unParsedPrice = (deserts || [])
    .map((phase) => {
      return phase.value;
    })
    .reduce((a, b) => {
      return (+a || 0) + (+b || 0);
    }, 0);
  return Utils.formatPrice(unParsedPrice);
});
const getCurrency = computed<string>(() => {
  const { currency = '' } = getAsset.value ?? {};
  if (currency === 'USD') {
    return '$';
  }
  return currency;
});

// #methods
const onSetPhases = () => {
  const { phases = [] } = getAsset.value ?? {};

  (phases as IPhase[]).forEach((phase: IPhase, index: number) => {
    deserts.push({
      ...phase,
      value: Number(getPhaseValues.value[index])
    });
  });
};

// #wathers
watch(deserts, () => {
  dispatch('setPhasesValues', deserts);
});

// #hooks
onMounted(() => {
  onSetPhases();
});
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors.scss';
.bid-phases {
  border: 1px solid $color-ui-blue-super-light;
  &__heading {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $color-ui-black !important;
  }
  &__row {
    padding: 16px 24px !important;
  }
  &__phase {
    &-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $color-ui-black;
    }
    &-description {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $color-ui-gray-secondary !important;
    }
    &-input {
      & .v-input__control .v-field__input {
        text-align: right;
      }
    }
  }
  &__total {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: $color-ui-black !important;
  }
}

.bid-phases__tr {
  & .bid-phases__row:first-child {
    width: 70% !important;
  }
  & .bid-phases__row {
    width: 20%;
  }
}
</style>
