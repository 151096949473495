<template>
  <app-card-info
    v-if="isLoading || (getPreBidOwner && invitieesList.length)"
    card-title="Law Firm Information"
    class="law-firm"
    :loading="isLoading"
  >
    <div v-if="getPreBidOwner" class="d-flex">
      <div class="law-firm__field-name w-50">
        {{ isLiveAuction ? 'Bid created by' : 'Proposal created by' }}
      </div>
      <div class="law-firm__field-value d-flex align-center">
        {{ getPreBidOwner }}
      </div>
    </div>
    <div v-if="invitieesList.length" class="d-flex mt-2">
      <div class="law-firm__field-name w-50">Other invitees</div>
      <div class="d-flex flex-column law-firm__field-value">
        <div v-for="user in invitieesList" :key="user">
          {{ user }}
        </div>
      </div>
    </div>
  </app-card-info>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { IAsset } from '@/types/store';
import { IUserInfo } from '@/types/store';
import AppCardInfo from '@/components/App/AppCardInfo.vue';
import { IListGrantsParams } from '@/types/API';
import { RAW_ASSETS_STATUSES } from '@/constants/statuses';

// #constants
const { dispatch, getters } = useStore();

// #data
const isLoading = ref<boolean>(false);
const grants = ref([]);

// #computed
const getAsset = computed<IAsset>(() => {
  return getters['getAsset'];
});
const getUser = computed<IUserInfo>(() => {
  return getters['getUser'];
});
const isLiveAuction = computed(() => {
  const { status = '' } = getAsset.value ?? {};
  return status === RAW_ASSETS_STATUSES.LIVE_AUCTION;
});
const assetId = computed<string>(() => {
  const { id = '' } = getAsset.value ?? {};
  return id;
});
const companyId = computed<string>(() => {
  const {
    company = {
      id: ''
    }
  } = getUser.value ?? {};
  const { id = '' } = company ?? {};
  return id;
});
const getCompanyGrant = computed<{
  preBid: { userId: null | string; updatedAt: string | null };
} | null>(() => {
  if (!grants.value.length) {
    return null;
  }
  const {
    companyGrant = {
      preBid: {
        updatedAt: null,
        userId: null
      }
    }
  } = grants.value[0] ?? {};
  return companyGrant;
});
const getPreBidOwner = computed<string | null>(() => {
  if (!getCompanyGrant.value) {
    return null;
  }
  const {
    preBid = {
      userId: null
    }
  } = getCompanyGrant.value ?? {};

  const { userId: preBidUserId = '' } = preBid ?? {};
  const preBidUser = grants.value.find(({ userId = '' }) => {
    return preBidUserId === userId;
  });
  if (!preBidUser) {
    return null;
  }
  const {
    user = {
      firstName: '',
      lastName: ''
    }
  } = preBidUser ?? {};

  const { firstName = '', lastName = '' } = user ?? {};
  return `${firstName} ${lastName}`;
});
const invitieesList = computed<string[]>(() => {
  return grants.value
    .filter(({ access, userId, companyGrant, user }) => {
      const {
        preBid = {
          userId: ''
        }
      } = companyGrant ?? {};
      const { userId: preBidUserId = '' } = preBid ?? {};
      const { email: grantEmail = '' } = user ?? {};
      const { email: currentUserEmail = '' } = getUser.value ?? {};

      return (
        access !== 'owner' &&
        preBidUserId !== userId &&
        currentUserEmail !== grantEmail
      );
    })
    .map(({ user }) => {
      const { firstName = '', lastName = '', email = '' } = user ?? {};
      if (!firstName || !lastName) {
        return email;
      }

      return `${firstName} ${lastName}`;
    });
});

// #methods
const onGetListGrants = async (): Promise<void> => {
  const params: IListGrantsParams = {
    assetId: assetId.value,
    companyId: companyId.value,
    limit: 50,
    nextToken: null
  };

  isLoading.value = true;
  try {
    const { data } = await dispatch('getListGrants', params);
    const {
      listGrants = {
        items: []
      }
    } = data ?? {};
    const { items = [] } = listGrants ?? {};

    grants.value = items;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

// #hooks
onMounted(async () => {
  await onGetListGrants();
});
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors.scss';

.law-firm {
  &__field-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: $color-ui-black;
    min-width: 125px;
  }
  &__field-value {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
