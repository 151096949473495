const RAW_ASSETS_STATUSES: { [key: string]: string } = {
  PLANNED: 'planned',
  ACTIVE: 'active',
  PRE_AUCTION: 'preAuction',
  PAUSED: 'paused',
  LIVE_AUCTION: 'liveAuction',
  COMPLETED: 'completed',
  PLANNED_PRE_AUCTION: 'planned_preAuction',
  PLANNED_LIVE_AUCTION: 'planned_liveAuction'
};
const ASSETS_STATUSES: { [key: string]: string } = {
  [RAW_ASSETS_STATUSES.ACTIVE]: 'Offer submission',
  [RAW_ASSETS_STATUSES.PRE_AUCTION]: 'Offer submission',
  [RAW_ASSETS_STATUSES.PAUSED]: 'Live-auction',
  [RAW_ASSETS_STATUSES.LIVE_AUCTION]: 'Live-auction',
  [RAW_ASSETS_STATUSES.COMPLETED]: 'Completed'
};
const ASSET_COLORS: { [key: string]: string } = {
  // $color-ui-blue-primary
  paused: '#18A1C8',
  // $color-ui-blue-primary
  liveAuction: '#18A1C8',
  // $color-ui-yellow-dark
  active: '#6D5E00',
  // $color-ui-yellow-dark
  preAuction: '#6D5E00',
  // $color-ui-blue-heading
  completed: '#154A6B'
};

export { ASSET_COLORS, ASSETS_STATUSES, RAW_ASSETS_STATUSES };
