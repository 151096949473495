<template>
  <div v-if="isReadonlyInput" class="bid-description-wrapper">
    <p class="bid-description-wrapper__title">Comments</p>
    <div
      class="bid-description-wrapper__text"
      v-html="getFormattedBidDescription"
    />
  </div>
  <v-textarea
    v-else
    label="Comments"
    variant="outlined"
    color="accentColor"
    auto-grow
    rows="3"
    :disabled="isDisabledForm"
    row-height="25"
    hide-details
    shaped
    :model-value="getBidDescription"
    @update:model-value="setBidDescription"
  />
</template>

<script setup lang="ts">
import { withDefaults, defineProps } from 'vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { RAW_ASSETS_STATUSES } from '@/constants/statuses';
import { Utils } from '@/utils';

// #interfaces
interface IProps {
  isDisabledForm: boolean;
}

// #props
withDefaults(defineProps<IProps>(), {
  isDisabledForm: false
});

// #constants
const { getters, commit } = useStore();

// #computed
const getBidDescription = computed<string>(() => {
  return getters['getBidDescription'];
});

const getFormattedBidDescription = computed<string>(() => {
  return Utils.getFormattedComment(getBidDescription.value);
});

const auctionStatus = computed<string>(() => {
  const { status } = getters['getAsset'] ?? {};
  return status;
});
const isReadonlyInput = computed<boolean>(() => {
  const status = auctionStatus.value;

  return ![
    RAW_ASSETS_STATUSES.ACTIVE,
    RAW_ASSETS_STATUSES.PRE_AUCTION
  ].includes(status);
});

// #methods
const setBidDescription = (val: string) => {
  commit('setBidDescription', val);
};
</script>
<style lang="scss">
@import '@/assets/style/_colors.scss';
.bid-description-wrapper {
  &__title {
    color: $color-ui-black;
    margin-bottom: 12px;
  }
  &__text {
    font-size: 14px;
    color: $color-ui-gray-secondary-darken;
  }
}
</style>
