<template>
  <div class="bid-currency d-flex align-center">
    <v-icon color="#BDBDBD" class="mr-2">mdi-credit-card-outline</v-icon>
    Payment Currency: {{ getCurrency }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { IAsset } from '@/types/store';

// #constants
const { getters } = useStore();

// #computed
const getAsset = computed<IAsset>(() => {
  return getters['getAsset'];
});
const getCurrency = computed<string>(() => {
  const { currency = '' } = getAsset.value ?? {};
  return currency;
});
</script>

<style scoped lang="scss">
.bid-currency {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>
