<template>
  <app-card-info card-title="Offer Details" class="auction-info">
    <div class="auction-info__status">
      Status:
      <app-asset-status :status="getStatus" class="ml-2" />
      <slot name="biddersTable"></slot>
      <app-asset-progress class="mt-10" />
      <div class="mt-4">
        <span>{{ getEndTitle }}</span>
        <app-asset-timer :end-date="getEndDate" class="auction-info__timer" />
      </div>
    </div>
  </app-card-info>
</template>

<script setup lang="ts">
import AppAssetStatus from '@/components/App/AppAssetStatus.vue';
import AppCardInfo from '@/components/App/AppCardInfo.vue';
import AppAssetProgress from '@/components/App/AppAssetProgress.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { IAsset } from '@/types/store';
import AppAssetTimer from '@/components/App/AppAssetTimer.vue';
import { RAW_ASSETS_STATUSES } from '@/constants/statuses';

// #constants
const { getters } = useStore();

// #computed
const asset = computed<IAsset>(() => {
  return getters['getAsset'];
});
const getStatus = computed<string>(() => {
  const { status } = asset.value ?? {};
  return status;
});
const getEndDate = computed<string>(() => {
  const { auction } = asset.value ?? {};
  const { endDate = '' } = auction ?? {};
  return endDate;
});
const isLiveAuction = computed<boolean>(() => {
  return RAW_ASSETS_STATUSES.LIVE_AUCTION === getStatus.value;
});
const getEndTitle = computed<string>(() => {
  return isLiveAuction.value
    ? 'Live-auction ends in:'
    : 'Offer submission ends in:';
});
</script>

<style scoped lang="scss">
.auction-info {
  padding-bottom: 24px !important;
  &__status {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  &__timer {
    width: 100%;
    max-width: 300px;
    margin-left: -10px;
  }
}
</style>
