<template>
  <div
    v-if="getParsedStatus"
    class="asset-status"
    :style="{
      backgroundColor: getStatusColor
    }"
  >
    {{ getParsedStatus }}
  </div>
</template>
<script lang="ts" setup>
import { defineProps, withDefaults, computed } from 'vue';
import { ASSETS_STATUSES, ASSET_COLORS } from '@/constants/statuses';

// #interfaces
interface IProps {
  status: string;
}

// #props
const props = withDefaults(defineProps<IProps>(), {
  status: ''
});

// #computed
const getParsedStatus = computed<string>(() => {
  return ASSETS_STATUSES[props.status] || '';
});
const getStatusColor = computed<string>(() => {
  return ASSET_COLORS[props.status] || '';
});
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors.scss';

.asset-status {
  display: inline-block !important;
  background: grey;
  padding: 2px 10px;
  border-radius: 24px;
  color: $color-ui-white;
  font-weight: 500;
  font-size: 14px;
  width: max-content;
}
</style>
