<template>
  <div class="d-flex align-center mt-6">
    <v-progress-linear
      class="asset__progress"
      :model-value="progress"
      bg-color="pink-lighten-3"
      color="#18A1C8"
    />
    <span class="asset__progress-value">{{ progress || 0 }}%</span>
  </div>
</template>

<script lang="ts" setup>
import { computed, unref, onMounted, ref, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { differenceInMilliseconds, startOfDay, endOfDay } from 'date-fns';

// #constants
const { state } = useStore();

// #data
const now = ref<number>(Date.now());
const intervalId = ref<ReturnType<typeof setInterval> | null>(null);

// #computed
const diff = computed<number>(() => {
  const { timeDiff } = state.sync ?? {};
  return timeDiff || 0;
});

const progress = computed<number>(() => {
  const {
    auction = {
      endDate: '',
      startDate: ''
    }
  } = unref(state.asset) ?? {};
  const { endDate = '', startDate = '' } = auction ?? {};
  const timeDiff = diff.value * 1000; // in milliseconds;
  let start = startDate ? new Date(startDate) : startOfDay(now.value);
  let end = endDate ? new Date(endDate) : endOfDay(now.value);

  const totalMillisInRange = differenceInMilliseconds(end, start);
  const elapsedMillis = differenceInMilliseconds(now.value, start) + timeDiff;
  const result = Math.max(
    0,
    Math.min(100, (100 * elapsedMillis) / totalMillisInRange)
  );
  return Math.round(result);
});

// #hooks
onMounted(() => {
  intervalId.value = setInterval(() => {
    now.value = Date.now();
  }, 1000);
});

onBeforeUnmount(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = null;
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors.scss';

.asset {
  &__progress {
    left: 46% !important;
    &-value {
      color: $color-ui-gray-secondary;
      font-weight: 400;
      font-size: 12px;
      padding-left: 8px;
    }
  }
}
</style>
