<template>
  <v-dialog v-model="dialog" width="412">
    <template #activator="{ props }">
      <v-btn
        variant="outlined"
        :disabled="isLoading || disabled"
        :loading="isDraftLoading"
        color="#18A1C8"
        v-bind="props"
        >Save as draft</v-btn
      >
    </template>

    <v-card class="save-as-draft__card">
      <v-card-title class="save-as-draft__title">
        Proposal Submission Confirmation
      </v-card-title>
      <v-card-text class="save-as-draft__text">
        By clicking "Submit", your proposal will become visible to the company
        requesting the services . Please be aware, action does not finalize your
        bid. You still maintain the flexibility to modify your proposal up until
        the deadline of the offer submission phase.
        <br />
        If you choose not to make any changes, the proposal submitted now will
        be recognized as your starting bid in the auction.
        <br />
        The "Save as Draft" button allows you to save your progress without
        making your proposal visible to the company requesting the services.
      </v-card-text>
      <v-card-actions class="save-as-draft__actions">
        <v-btn
          color="primary"
          variant="text"
          :disabled="isLoading || isDraftLoading"
          @click="dialog = false"
          >Cancel</v-btn
        >
        <div class="save-as-draft__right-side">
          <v-btn
            variant="outlined"
            :disabled="isLoading || isDraftLoading"
            :loading="isDraftLoading"
            color="#18A1C8"
            @click="onSaveAsDraft"
            >Save as draft</v-btn
          >
          <v-btn
            variant="flat"
            :disabled="isLoading || isDraftLoading"
            :loading="isLoading"
            color="#18A1C8"
            @click="onSubmit"
            >Submit</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useBid from '@/composables/useBid';
import { withDefaults, defineProps } from 'vue';

// #interfaces
interface IProps {
  disabled: boolean;
}

// #props
withDefaults(defineProps<IProps>(), {
  disabled: false
});

// #constants
const {
  onUpdateProposal,
  onCreateProposal,
  onUpdateBid,
  isLoading,
  isDraftLoading,
  isProposal,
  proposalId
} = useBid();

// #data
const dialog = ref(false);

// #methods
const onSaveAsDraft = async () => {
  proposalId.value ? onUpdateProposal('draft') : onCreateProposal('draft');
};
const onSubmit = () => {
  if (isProposal.value) {
    return proposalId.value ? onUpdateProposal() : onCreateProposal();
  }
  onUpdateBid();
};
</script>
<style lang="scss">
@import '@/assets/style/_colors.scss';

.save-as-draft {
  &__card {
    padding: 24px !important;
  }
  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    padding: 0 !important;
    margin-bottom: 8px;
  }
  &__text {
    color: $color-ui-gray-secondary-darken;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0 !important;
    margin-bottom: 24px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
