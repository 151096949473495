<template>
  <v-row v-if="targetDate.value" class="mb-1 bids-timing">
    <v-col cols="12">
      <v-row class="countdown" cols="12">
        <v-col cols="3" class="countdown-wrapper">
          <div class="countdown-block countdown-block__days">
            <span class="value">{{ targetDate.countdown.days }}</span>
            <span class="unit">Days</span>
          </div>
        </v-col>
        <v-col cols="3" class="countdown-wrapper">
          <div class="countdown-block">
            <span class="value">{{ targetDate.countdown.hours }}</span>
            <span class="unit">Hours</span>
          </div>
        </v-col>
        <v-col cols="3" class="countdown-wrapper">
          <div class="countdown-block">
            <span class="value">{{ targetDate.countdown.minutes }}</span>
            <span class="unit">Minutes</span>
          </div>
        </v-col>
        <v-col cols="3" class="countdown-wrapper">
          <div class="countdown-block">
            <span class="value">{{ targetDate.countdown.seconds }}</span>
            <span class="unit">Seconds</span>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { watchEffect, defineProps, computed } from 'vue';
import useCountdown from '@/composables/useCountdown';
import { useStore } from 'vuex';

// #constants
const { setupCountdown, targetDate } = useCountdown();
const { state } = useStore();

// #props
const props = defineProps({
  endDate: {
    type: String,
    default: ''
  }
});

// #computed
const timeDiff = computed<number>(() => {
  const {
    sync = {
      timeDiff: 0
    }
  } = state ?? {};
  const { timeDiff = 0 } = sync ?? {};
  return timeDiff || 0;
});

// #watch
watchEffect(() => {
  if (props.endDate) {
    setupCountdown(timeDiff.value, new Date(props.endDate));
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/colors';

.bids-timing {
  width: 100%;
  min-width: 195px;
  margin: auto;
  margin-top: 16px;
}
.countdown-block {
  height: 72px !important;
  width: 57px !important;
  background: linear-gradient(0deg, $color-ui-white, $color-ui-white),
    $color-ui-blue-info;
  border: 2px solid $color-ui-blue-primary;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .value {
    font-family: Oswald, sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: normal;
    color: rgba($color-ui-black, 0.87);
  }
  .unit {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-weight: 400;
    font-size: 12px;
    color: #616161;
    line-height: normal;
  }
  &__days {
    &:after {
      display: none;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    color: $color-ui-gray-darker;
    font-size: 36px;
  }
}
.countdown {
  font-size: 30px;
  padding: 0 !important;
}
.countdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
</style>
