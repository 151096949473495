<template>
  <v-app class="app">
    <div>
      <div class="app__content">
        <template v-if="userEmail">
          <div class="app__title d-flex align-center">
            <v-btn variant="text" @click="onClose">
              <v-icon class="mr-6" size="32" color="#154A6B"
                >mdi-arrow-left</v-icon
              >
            </v-btn>
            <h3 class="app__title-heading">{{ title }}</h3>
          </div>

          <v-main>
            <router-view />
          </v-main>
        </template>

        <div v-else class="d-flex align-center justify-center h-full">
          <v-progress-circular indeterminate size="48" color="#18A1C8" />
        </div>

        <v-snackbar
          v-model="snackbar.isOpened"
          :timeout="5000"
          bottom
          :color="snackbar.color"
        >
          {{ snackbar.text }}

          <span v-if="snackbar.subtext" class="d-block text-small">
            {{ snackbar.subtext }}
          </span>
          <template #actions>
            <v-btn icon @click="closeSnackbar">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </v-app>
</template>

<script setup lang="ts">
import { computed, onMounted, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ISnackBar } from '@/types/store';

// #constants
const router = useRouter();
const { commit, dispatch, getters } = useStore();

// #computed
const title = computed<string>(() => {
  return getters['getAssetTitle'];
});
const userEmail = computed<string | null>(() => {
  return getters['getUserEmail'];
});
const snackbar = computed<ISnackBar>(() => {
  return getters['getSnackBar'];
});

// #methods
const onClose = (): void => {
  dispatch('close');
};
const closeSnackbar = () => {
  commit('closeSnackbar');
};
const setCredentials = (credentials: { [x: string]: string }) => {
  Object.keys(credentials).forEach((key) => {
    localStorage.setItem(key, credentials[key]);
  });

  dispatch('fetchUserInfo');
};

// #hooks
onMounted(() => {
  window.addEventListener('message', (ev) => {
    const action = ev?.data?.action;
    if (action === 'credentials') {
      setCredentials(ev.data.data);
    } else if (action === 'auction-info') {
      commit('setAssetInfo', ev.data.data);
      router.push('/bid');
    } else if (action === 'time-diff') {
      commit('setTimeDiff', ev.data.data.diff);
    }
  });
});
onBeforeMount(() => {
  dispatch('initSync');
});
</script>

<style lang="scss">
@import 'src/assets/style/_colors.scss';

.app {
  display: block !important;
  position: relative;
  padding: 24px;
  background: $color-ui-white-background !important;
  &__content {
    width: 100%;
    max-width: 1400px;
    margin: auto;
  }
  &__title {
    &-heading {
      font-weight: 400;
      font-size: 38px;
      line-height: 56px;
      color: $color-ui-blue-heading !important;
    }
  }
}
:deep(.v-application__wrap) {
  align-items: start !important;
}
</style>
