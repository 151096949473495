import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import * as Sentry from '@sentry/vue';

import './assets/style/global.scss';
import money from 'v-money3';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store';

import { Utils } from '@/utils';
import { Amplify } from 'aws-amplify';
import { awsConfig } from './aws_config';

import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import * as process from 'process';

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          // $color-ui-blue-primary
          primary: '#18A1C8',
          // $color-ui-blue-dark
          darkBlue: '#0a1f51',
          // $color-ui-gray
          secondary: '#424242',
          // $color-ui-blue-accent
          accent: '#82B1FF',
          // $color-ui-red
          error: '#FF5252',
          // $color-ui-blue-info
          info: '#2196F3',
          // $color-ui-green
          success: '#4CAF50',
          // $color-ui-yellow
          warning: '#FFC107',
          // $color-ui-gray-super-light
          grey: '#F2F2F2',
          // $color-ui-blue-primary
          accentColor: '#18A1C8'
        }
      }
    }
  }
});

Amplify.configure(awsConfig);

const app = createApp(App);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.config.globalProperties.$buckets = awsConfig.Storage.AWSS3.buckets;

app.directive('currency', {
  mounted: (value: number) => {
    if (!value || typeof value !== 'number') return '';
    return Utils.formatPrice(value);
  }
});

// #Sentry
if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          {
            event,
            hint
          }
        );
      }
      return event;
    }
  });

  app.mixin(
    Sentry.createTracingMixins({
      trackComponents: false,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update']
    })
  );

  Sentry.attachErrorHandler(app, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  });
}

app.use(vuetify).use(store).use(money).use(router).mount('#app');
